<template>
  <product-form v-if="productDetail" :product="productDetail" @submit="update" />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ProductForm from '@/components/Product/ProductForm'
import { buildProductUploadUrls } from '@/shared/file'

export default {
  mixins: [navigationMixin],
  components: {
    ProductForm
  },
  computed: {
    ...mapState('product', ['productDetail']),
    ...mapState('shared', ['error']),
    ...mapState('file', ['files'])
  },
  methods: {
    ...mapActions('product', ['getProduct', 'updateProduct']),
    ...mapActions('file', ['saveFiles']),
    ...mapActions('notification', ['showNotification']),
    async update (product) {
      await this.updateProduct(product)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      if (this.files.length > 0) {
        const uploadUrlInput = buildProductUploadUrls(product.product_id, this.files)
        await this.saveFiles(uploadUrlInput)

        if (this.error) {
          this.showNotification({ message: 'Ocurrió un error al subir las imágenes, por favor inténtelo nuevamente', color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
          return
        }
      }

      this.showNotification({ message: 'Producto actualizado exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
      this.goToRouteByName('products')
    }
  },
  mounted () {
    this.getProduct(this.$route.params.id)
  }
}
</script>
<style scoped>
</style>
